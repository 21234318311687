<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Organizations" />
    </q-breadcrumbs>

  <div class="row justify-between items-end q-my-sm">
    <div class="col full-height text-h6">
      Total Organizations
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}} Posts
      </span>
    </div>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>

  <table class="table table-striped table-sm full-width">
    <tr v-for="org in recs" :key="org._id">

    <td>
      <div class="text-body1 text-weight-medium">
        <router-link :to="{ name: 'orgView', params: { recId: org._id }}" class="text-primary">{{org.name}}</router-link>
      </div>
    </td>
    <td class="text-right text-sm">
      <div v-if="org.location" class="text-no-wrap">
      {{org.location.city}}<span v-if="org.location.state_code">,
      <router-link :to="{ name: 'orgStateList', params: { stateCode: org.location.state_code, pageId: 1 } }" class="text-primary">{{org.location.state_code}}</router-link></span>
      {{org.location.country_code}}
      </div>
    </td>

    </tr>
  </table>

  <div class="row justify-end items-end q-my-sm">

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </div>

</div>
</template>

<script>
import storeRecListMixin from "src/mixins/store_rec_list.mixin";
import orgModule from "../../store";
import { mapState, mapGetters } from 'vuex';

export default {
  mixins: [
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'organizations',
      vuexCollection: 'organization.recs',
      vuexPath: 'organization',
      vuexPageRequestAction: orgModule.actionTypes.LoadRecordsPageRequest,

      // qry: { 'skills': this.$route.params.skill },
    }
  },
  computed: {
      ...mapState('organization', {
        loading: 'loading',
      }),
  },
  watch: {
    // call again the method if the route changes
  },
  created () {
  },
  methods: {

  },
}
</script>



